import { type FC, type ReactNode } from "react";
import PropTypes from "prop-types";

import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSettings } from "src/hooks/use-settings";

import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";

import { UserDataProvider } from "src/contexts/snug/userData-context";
import { ErrorLogProvider } from "src/contexts/snug/error-logging-context";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings();

  if (settings.layout === "horizontal") {
    return (
      
        <UserDataProvider>
          <ErrorLogProvider>
          <HorizontalLayout
            navColor={settings.navColor}
            {...props}
          />
          </ErrorLogProvider>
        </UserDataProvider>
      
    );
  }

  return (
    
      <UserDataProvider>
        <ErrorLogProvider>
        <VerticalLayout
          navColor={settings.navColor}
          {...props}
        />
        </ErrorLogProvider>
      </UserDataProvider>
    
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
