import type { FC } from 'react';
import propTypes from 'prop-types';
import type { SxProps } from '@mui/system/styleFunctionSx';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';

interface PricingPlanProps {
  step: string;
  stepName: string;
  description: string;
  features: string[];
  sx?: SxProps<Theme>;
}

export const PricingPlan: FC<PricingPlanProps> = (props) => {
  const {
    step,
    stepName,
    description,
    features,
    sx,
    ...other
  } = props;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb:5,
        ...sx
      }}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography
          sx={{ mt: 2 }}
          variant="h4"
        >
          {stepName}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{ mt: 2 }}
          variant="body1"
        >
          {description}
        </Typography>
      </Box>
      <Divider />
      
      
      {stepName === 'Agency White Label' &&
      <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='subtitle1' sx={{color:'#ff9c07', mb:1}}>Setup Fee (one-time)</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                variant="body2"
              >
                $6,000 one-time
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='subtitle1' sx={{color:'#ff9c07', mb:1}}>Usage Fees</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                variant="body2"
              >
                Will Package - $150
              </Typography>
              <Typography
                variant="body2"
              >
                Trust Package - $400
              </Typography>
              <Typography
                variant="body2"
              >
                Membership - Coming soon!
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='subtitle1' sx={{color:'#ff9c07', mb:1}}>Features</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                variant="body2"
              >
                White label client portal
              </Typography>
              <Typography
                variant="body2"
              >
                White label back office dashboard
              </Typography>
              <Typography
                variant="body2"
              >
                Unlimited clients
              </Typography>
              <Typography
                variant="body2"
              >
                Unlimited agents and admins
              </Typography>
        </Stack>
      </Box>
      </>
      }
      {stepName === 'Standard White Label' &&
      <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='subtitle1' sx={{color:'#ff9c07', mb:1}}>Setup Fee (one-time)</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                variant="body2"
              >
                $1,250 one-time
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='subtitle1' sx={{color:'#ff9c07', mb:1}}>Usage Fees</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                variant="body2"
              >
                Will Package - $200
              </Typography>
              <Typography
                variant="body2"
              >
                Trust Package - $500
              </Typography>
              <Typography
                variant="body2"
              >
                Membership - Coming soon!
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='subtitle1' sx={{color:'#ff9c07', mb:1}}>Features</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                variant="body2"
              >
                White label client portal
              </Typography>
              <Typography
                variant="body2"
              >
                Snug-branded back office dashboard
              </Typography>
              <Typography
                variant="body2"
              >
                Unlimited clients
              </Typography>
              <Typography
                variant="body2"
              >
                Unlimited agents and admins
              </Typography>
        </Stack>
      </Box>
      </>
      }
    </Card>
  );
};

PricingPlan.propTypes = {
  step: propTypes.string.isRequired,
  stepName: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  features: propTypes.array.isRequired,
  sx: propTypes.object
};
