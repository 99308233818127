import {
  useState,
} from "react";
import type { FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { paths } from "src/paths";
import { PopupModal } from "react-calendly";
import useUserDataProvider from "src/contexts/snug/userData-context";
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import { Link as RouterLink } from 'react-router-dom'; // if using react-router
import { Link } from '@mui/material';

interface FreeTrialBannerProps {
  trialEnd?: number;
}

export const FreeTrialBanner: FC<FreeTrialBannerProps> = (props) => {

  const [demoIsOpen, setDemoIsOpen] = useState(false);

  const {
    userData,
    isCobranding,
    isWhiteLabel,
  } = useUserDataProvider();

  return (
    <>
      <Box>
        <Container maxWidth="lg">
          <Box
            sx={{
              backgroundColor: "#1C2536",
              p: 1,
              px: 3,
              py: { xs: 1, md: 1.5 },
              borderRadius: 2,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0, sm: 2 }}
              alignItems={"center"}
              textAlign={"left"}
              justifyContent={"space-between"}
              sx={{ pb: { xs: 1, sm: 0 } }}
            >
              {(isCobranding || isWhiteLabel) ?
                <Stack spacing={2} direction='row' alignItems={'center'}>
                  <EventRepeatOutlinedIcon sx={{ color: 'white' }} />
                  <Typography
                    color="white"
                    variant="button"
                    sx={{
                      p: { xs: 1.5, sm: 0 },
                      lineHeight: 1.3,
                      textTransform: "unset",
                      display: { xs: "inherit", md: "inherit" },
                    }}
                  >
                    Onboarding in progress. Continue your&nbsp;{` `}
                    <Link
                      component={RouterLink}
                      to={paths.dashboard.upgrade}
                      sx={{ color: "inherit", cursor: "pointer", textDecoration: 'underline' }}
                    >
                      onboarding steps here
                    </Link>.
                  </Typography>
                </Stack>
                :
                <Typography
                  color="primary.text"
                  variant="button"
                  sx={{
                    p: { xs: 1.5, sm: 0 },
                    lineHeight: 1.3,
                    textTransform: "unset",
                    display: { xs: "none", md: "inherit" },
                  }}
                >
                  Upgrade to Pro for custom pricing and branding. If you have any questions,&nbsp;{" "}
                  <span
                    onClick={() => setDemoIsOpen(true)}
                    style={{ color: "inherit", cursor: "pointer", textDecoration: 'underline' }}
                  >
                    schedule a demo
                  </span>
                  .
                </Typography>
              }

              {!(isCobranding || isWhiteLabel) &&
                <Button
                  endIcon={<EastOutlinedIcon />}
                  variant="outlined"
                  color="inherit"
                  size="small"
                  href={paths.dashboard.upgrade}
                  sx={{
                    borderRadius: 100,
                  }}
                >
                  Upgrade to Pro
                </Button>
              }

            </Stack>
          </Box>
        </Container>
      </Box>
      <PopupModal
        url="https://calendly.com/d/cpmw-y6c-8pq?hide_gdpr_banner=1&utm_source=dashboard"
        onModalClose={() => setDemoIsOpen(false)}
        open={demoIsOpen}
        prefill={{
          email: userData?.contact_email,
          name: userData?.full_name,
        }}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")!}
      />
    </>
  );
};
