import React, { createContext, useContext, useEffect, useCallback, ReactNode } from 'react';
import { useEstateAppApiContext } from 'src/contexts/snug/estateAppApi-context';
import axios from 'axios';
interface ErrorLogContextType {
  logError: (error: Error, componentStack?: string, additionalContext?: Record<string, any>) => void;
}

const ErrorLogContext = createContext<ErrorLogContextType | undefined>(undefined);

interface ErrorBoundaryProps {
  children: ReactNode;
  onError: (error: Error, errorInfo: React.ErrorInfo) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <p>We've been notified and are working to fix the issue.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export const ErrorLogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { estateAppApiInstance } = useEstateAppApiContext();

  const logError = useCallback(async (
    error: Error,
    componentStack?: string,
    additionalContext: Record<string, any> = {}
  ) => {
    try {
      const errorLog = {
        input: JSON.stringify({
          message: error.message,
          stack: error.stack,
          componentStack,
          timestamp: new Date().toISOString(),
          url: window.location.href,
          userAgent: navigator.userAgent,
          context: {
            ...additionalContext,
            route: window.location.pathname,
          },
        }),
        protocol: 'error-logs.json',
        translation: 'cursor.json',
        model: 'anthropic',
        send_email: true
      };

      // Send to Turbo API
      await axios.post('/v1/turbo/', errorLog, {
        headers: {
          Authorization: localStorage.getItem("s_at") ? `Bearer ${localStorage.getItem("s_at")}` : '',
          "X-User-Domain": window.location.host.replace("https://", "")
        }
      });

    } catch (e) {
      // Fallback to console if API call fails
      console.error('Failed to send error to Turbo API:', e);
    }
  }, [estateAppApiInstance]);

  useEffect(() => {
    // Handle uncaught errors
    const handleGlobalError = (event: ErrorEvent) => {
      event.preventDefault();
      logError(event.error);
    };

    // Handle unhandled promise rejections
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      event.preventDefault();
      logError(new Error(event.reason));
    };

    window.addEventListener('error', handleGlobalError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleGlobalError);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, [logError]);

  const handleErrorBoundaryError = useCallback((error: Error, errorInfo: React.ErrorInfo) => {
    logError(error, errorInfo.componentStack);
  }, [logError]);

  return (
    <ErrorLogContext.Provider value={{ logError }}>
      <ErrorBoundary onError={handleErrorBoundaryError}>
        {children}
      </ErrorBoundary>
    </ErrorLogContext.Provider>
  );
};

export const useErrorLog = () => {
  const context = useContext(ErrorLogContext);
  if (!context) {
    throw new Error('useErrorLog must be used within an ErrorLogProvider');
  }
  return context;
};

// Hook for manual error logging
export const useLogError = () => {
  const { logError } = useErrorLog();
  return logError;
}; 